export const countriesName = {
    Spain: 0,
    Italy: 1,
    Germany: 2,
    France: 3,
    China: 4,
    US: 5,
    Iran: 6,
    "United Kingdom": 7,
    Turkey: 8,
    Switzerland: 9,
    Belgium: 10,
    Netherlands: 11,
    Austria: 12,
    Portugal: 13,
    Brazil: 14,
    "Korea, South": 15,
    Israel: 16,
    Canada: 17,
    Sweden: 18,
    Norway: 19,
    Russia: 20,
    Ireland: 21,
    Czechia: 22,
    Chile: 23,
    Denmark: 24,
    Poland: 25,
    Romania: 26,
    Malaysia: 27,
    Ecuador: 28,
    India: 29,
    Philippines: 30,
    Pakistan: 31,
    Japan: 32,
    Luxembourg: 33,
    Australia: 34,
    "Saudi Arabia": 35,
    Peru: 36,
    Indonesia: 37,
    Thailand: 38,
    Finland: 39,
    Serbia: 40,
    Mexico: 41,
    Panama: 42,
    "United Arab Emirates": 43,
    "Dominican Republic": 44,
    Greece: 45,
    "South Africa": 46,
    Qatar: 47,
    Iceland: 48,
    Colombia: 49,
    Argentina: 50,
    Algeria: 51,
    Singapore: 52,
    Ukraine: 53,
    Croatia: 54,
    Egypt: 55,
    Estonia: 56,
    "New Zealand": 57,
    Morocco: 58,
    Slovenia: 59,
    Iraq: 60,
    Moldova: 61,
    Armenia: 62,
    Lithuania: 63,
    Hungary: 64,
    "Diamond Princess": 65,
    Bahrain: 66,
    "Bosnia and Herzegovina": 67,
    Cameroon: 68,
    Azerbaijan: 69,
    Kazakhstan: 70,
    Tunisia: 71,
    Belarus: 72,
    Kuwait: 73,
    "North Macedonia": 74,
    Latvia: 75,
    Bulgaria: 76,
    Lebanon: 77,
    Andorra: 78,
    Slovakia: 79,
    "Costa Rica": 80,
    Cyprus: 81,
    Uruguay: 82,
    "Taiwan*": 83,
    Albania: 84,
    Afghanistan: 85,
    "Burkina Faso": 86,
    Jordan: 87,
    Uzbekistan: 88,
    Cuba: 89,
    Oman: 90,
    Honduras: 91,
    "San Marino": 92,
    "Cote d'Ivoire": 93,
    Vietnam: 94,
    "West Bank and Gaza": 95,
    Nigeria: 96,
    Malta: 97,
    Mauritius: 98,
    Senegal: 99,
    Ghana: 100,
    Montenegro: 101,
    Niger: 102,
    "Sri Lanka": 103,
    Georgia: 104,
    Venezuela: 105,
    Bolivia: 106,
    "Congo (Kinshasa)": 107,
    Kyrgyzstan: 108,
    Kosovo: 109,
    Kenya: 110,
    Brunei: 111,
    Guinea: 112,
    Cambodia: 113,
    Paraguay: 114,
    Rwanda: 115,
    "Trinidad and Tobago": 116,
    Bangladesh: 117,
    Liechtenstein: 118,
    Monaco: 119,
    Madagascar: 120,
    "El Salvador": 121,
    Guatemala: 122,
    Djibouti: 123,
    Jamaica: 124,
    Barbados: 125,
    Uganda: 126,
    "Congo (Brazzaville)": 127,
    Mali: 128,
    Togo: 129,
    Ethiopia: 130,
    Zambia: 131,
    Eritrea: 132,
    Bahamas: 133,
    Guyana: 134,
    Benin: 135,
    Tanzania: 136,
    Burma: 137,
    Gabon: 138,
    Haiti: 139,
    Maldives: 140,
    Syria: 141,
    "Guinea-Bissau": 142,
    Libya: 143,
    "Equatorial Guinea": 144,
    Namibia: 145,
    "Antigua and Barbuda": 146,
    Angola: 147,
    Dominica: 148,
    Mongolia: 149,
    "Saint Lucia": 150,
    Liberia: 151,
    Fiji: 152,
    Grenada: 153,
    Sudan: 154,
    Laos: 155,
    Mozambique: 156,
    "Saint Kitts and Nevis": 157,
    Seychelles: 158,
    Suriname: 159,
    Chad: 160,
    Eswatini: 161,
    "MS Zaandam": 162,
    Nepal: 163,
    Zimbabwe: 164,
    "Central African Republic": 165,
    "Cabo Verde": 166,
    "Holy See": 167,
    "Saint Vincent and the Grenadines": 168,
    Somalia: 169,
    Botswana: 170,
    Mauritania: 171,
    Nicaragua: 172,
    "Sierra Leone": 173,
    Belize: 174,
    Bhutan: 175,
    Gambia: 176,
    Malawi: 177,
    "Western Sahara": 178,
    Burundi: 179,
    "Papua New Guinea": 180,
    "South Sudan": 181,
    "Timor-Leste": 182,
  }

  export const countries = [
    { country: "Spain", confirmedCount: 0 },
    { country: "Italy", confirmedCount: 0 },
    { country: "Germany", confirmedCount: 0 },
    { country: "France", confirmedCount: 0 },
    { country: "China", confirmedCount: 0 },
    { country: "US", confirmedCount: 0 },
    { country: "Iran", confirmedCount: 0 },
    { country: "United Kingdom", confirmedCount: 0 },
    { country: "Turkey", confirmedCount: 0 },
    { country: "Switzerland", confirmedCount: 0 },
    { country: "Belgium", confirmedCount: 0 },
    { country: "Netherlands", confirmedCount: 0 },
    { country: "Austria", confirmedCount: 0 },
    { country: "Portugal", confirmedCount: 0 },
    { country: "Brazil", confirmedCount: 0 },
    { country: "Korea, South", confirmedCount: 0 },
    { country: "Israel", confirmedCount: 0 },
    { country: "Canada", confirmedCount: 0 },
    { country: "Sweden", confirmedCount: 0 },
    { country: "Norway", confirmedCount: 0 },
    { country: "Russia", confirmedCount: 0 },
    { country: "Ireland", confirmedCount: 0 },
    { country: "Czechia", confirmedCount: 0 },
    { country: "Chile", confirmedCount: 0 },
    { country: "Denmark", confirmedCount: 0 },
    { country: "Poland", confirmedCount: 0 },
    { country: "Romania", confirmedCount: 0 },
    { country: "Malaysia", confirmedCount: 0 },
    { country: "Ecuador", confirmedCount: 0 },
    { country: "India", confirmedCount: 0 },
    { country: "Philippines", confirmedCount: 0 },
    { country: "Pakistan", confirmedCount: 0 },
    { country: "Japan", confirmedCount: 0 },
    { country: "Luxembourg", confirmedCount: 0 },
    { country: "Australia", confirmedCount: 0 },
    { country: "Saudi Arabia", confirmedCount: 0 },
    { country: "Peru", confirmedCount: 0 },
    { country: "Indonesia", confirmedCount: 0 },
    { country: "Thailand", confirmedCount: 0 },
    { country: "Finland", confirmedCount: 0 },
    { country: "Serbia", confirmedCount: 0 },
    { country: "Mexico", confirmedCount: 0 },
    { country: "Panama", confirmedCount: 0 },
    { country: "United Arab Emirates", confirmedCount: 0 },
    { country: "Dominican Republic", confirmedCount: 0 },
    { country: "Greece", confirmedCount: 0 },
    { country: "South Africa", confirmedCount: 0 },
    { country: "Qatar", confirmedCount: 0 },
    { country: "Iceland", confirmedCount: 0 },
    { country: "Colombia", confirmedCount: 0 },
    { country: "Argentina", confirmedCount: 0 },
    { country: "Algeria", confirmedCount: 0 },
    { country: "Singapore", confirmedCount: 0 },
    { country: "Ukraine", confirmedCount: 0 },
    { country: "Croatia", confirmedCount: 0 },
    { country: "Egypt", confirmedCount: 0 },
    { country: "Estonia", confirmedCount: 0 },
    { country: "New Zealand", confirmedCount: 0 },
    { country: "Morocco", confirmedCount: 0 },
    { country: "Slovenia", confirmedCount: 0 },
    { country: "Iraq", confirmedCount: 0 },
    { country: "Moldova", confirmedCount: 0 },
    { country: "Armenia", confirmedCount: 0 },
    { country: "Lithuania", confirmedCount: 0 },
    { country: "Hungary", confirmedCount: 0 },
    { country: "Diamond Princess", confirmedCount: 0 },
    { country: "Bahrain", confirmedCount: 0 },
    { country: "Bosnia and Herzegovina", confirmedCount: 0 },
    { country: "Cameroon", confirmedCount: 0 },
    { country: "Azerbaijan", confirmedCount: 0 },
    { country: "Kazakhstan", confirmedCount: 0 },
    { country: "Tunisia", confirmedCount: 0 },
    { country: "Belarus", confirmedCount: 0 },
    { country: "Kuwait", confirmedCount: 0 },
    { country: "North Macedonia", confirmedCount: 0 },
    { country: "Latvia", confirmedCount: 0 },
    { country: "Bulgaria", confirmedCount: 0 },
    { country: "Lebanon", confirmedCount: 0 },
    { country: "Andorra", confirmedCount: 0 },
    { country: "Slovakia", confirmedCount: 0 },
    { country: "Costa Rica", confirmedCount: 0 },
    { country: "Cyprus", confirmedCount: 0 },
    { country: "Uruguay", confirmedCount: 0 },
    { country: "Taiwan*", confirmedCount: 0 },
    { country: "Albania", confirmedCount: 0 },
    { country: "Afghanistan", confirmedCount: 0 },
    { country: "Burkina Faso", confirmedCount: 0 },
    { country: "Jordan", confirmedCount: 0 },
    { country: "Uzbekistan", confirmedCount: 0 },
    { country: "Cuba", confirmedCount: 0 },
    { country: "Oman", confirmedCount: 0 },
    { country: "Honduras", confirmedCount: 0 },
    { country: "San Marino", confirmedCount: 0 },
    { country: "Cote d'Ivoire", confirmedCount: 0 },
    { country: "Vietnam", confirmedCount: 0 },
    { country: "West Bank and Gaza", confirmedCount: 0 },
    { country: "Nigeria", confirmedCount: 0 },
    { country: "Malta", confirmedCount: 0 },
    { country: "Mauritius", confirmedCount: 0 },
    { country: "Senegal", confirmedCount: 0 },
    { country: "Ghana", confirmedCount: 0 },
    { country: "Montenegro", confirmedCount: 0 },
    { country: "Niger", confirmedCount: 0 },
    { country: "Sri Lanka", confirmedCount: 0 },
    { country: "Georgia", confirmedCount: 0 },
    { country: "Venezuela", confirmedCount: 0 },
    { country: "Bolivia", confirmedCount: 0 },
    { country: "Congo (Kinshasa)", confirmedCount: 0 },
    { country: "Kyrgyzstan", confirmedCount: 0 },
    { country: "Kosovo", confirmedCount: 0 },
    { country: "Kenya", confirmedCount: 0 },
    { country: "Brunei", confirmedCount: 0 },
    { country: "Guinea", confirmedCount: 0 },
    { country: "Cambodia", confirmedCount: 0 },
    { country: "Paraguay", confirmedCount: 0 },
    { country: "Rwanda", confirmedCount: 0 },
    { country: "Trinidad and Tobago", confirmedCount: 0 },
    { country: "Bangladesh", confirmedCount: 0 },
    { country: "Liechtenstein", confirmedCount: 0 },
    { country: "Monaco", confirmedCount: 0 },
    { country: "Madagascar", confirmedCount: 0 },
    { country: "El Salvador", confirmedCount: 0 },
    { country: "Guatemala", confirmedCount: 0 },
    { country: "Djibouti", confirmedCount: 0 },
    { country: "Jamaica", confirmedCount: 0 },
    { country: "Barbados", confirmedCount: 0 },
    { country: "Uganda", confirmedCount: 0 },
    { country: "Congo (Brazzaville)", confirmedCount: 0 },
    { country: "Mali", confirmedCount: 0 },
    { country: "Togo", confirmedCount: 0 },
    { country: "Ethiopia", confirmedCount: 0 },
    { country: "Zambia", confirmedCount: 0 },
    { country: "Eritrea", confirmedCount: 0 },
    { country: "Bahamas", confirmedCount: 0 },
    { country: "Guyana", confirmedCount: 0 },
    { country: "Benin", confirmedCount: 0 },
    { country: "Tanzania", confirmedCount: 0 },
    { country: "Burma", confirmedCount: 0 },
    { country: "Gabon", confirmedCount: 0 },
    { country: "Haiti", confirmedCount: 0 },
    { country: "Maldives", confirmedCount: 0 },
    { country: "Syria", confirmedCount: 0 },
    { country: "Guinea-Bissau", confirmedCount: 0 },
    { country: "Libya", confirmedCount: 0 },
    { country: "Equatorial Guinea", confirmedCount: 0 },
    { country: "Namibia", confirmedCount: 0 },
    { country: "Antigua and Barbuda", confirmedCount: 0 },
    { country: "Angola", confirmedCount: 0 },
    { country: "Dominica", confirmedCount: 0 },
    { country: "Mongolia", confirmedCount: 0 },
    { country: "Saint Lucia", confirmedCount: 0 },
    { country: "Liberia", confirmedCount: 0 },
    { country: "Fiji", confirmedCount: 0 },
    { country: "Grenada", confirmedCount: 0 },
    { country: "Sudan", confirmedCount: 0 },
    { country: "Laos", confirmedCount: 0 },
    { country: "Mozambique", confirmedCount: 0 },
    { country: "Saint Kitts and Nevis", confirmedCount: 0 },
    { country: "Seychelles", confirmedCount: 0 },
    { country: "Suriname", confirmedCount: 0 },
    { country: "Chad", confirmedCount: 0 },
    { country: "Eswatini", confirmedCount: 0 },
    { country: "MS Zaandam", confirmedCount: 0 },
    { country: "Nepal", confirmedCount: 0 },
    { country: "Zimbabwe", confirmedCount: 0 },
    { country: "Central African Republic", confirmedCount: 0 },
    { country: "Cabo Verde", confirmedCount: 0 },
    { country: "Holy See", confirmedCount: 0 },
    { country: "Saint Vincent and the Grenadines", confirmedCount: 0 },
    { country: "Somalia", confirmedCount: 0 },
    { country: "Botswana", confirmedCount: 0 },
    { country: "Mauritania", confirmedCount: 0 },
    { country: "Nicaragua", confirmedCount: 0 },
    { country: "Sierra Leone", confirmedCount: 0 },
    { country: "Belize", confirmedCount: 0 },
    { country: "Bhutan", confirmedCount: 0 },
    { country: "Gambia", confirmedCount: 0 },
    { country: "Malawi", confirmedCount: 0 },
    { country: "Western Sahara", confirmedCount: 0 },
    { country: "Burundi", confirmedCount: 0 },
    { country: "Papua New Guinea", confirmedCount: 0 },
    { country: "South Sudan", confirmedCount: 0 },
    { country: "Timor-Leste", confirmedCount: 0 },
  ]